import React, { useRef, useCallback, useEffect } from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css"; 
import { uploadEditorFile } from "../../../../services/CommonService";

function DraftEditor(props) {
  const { ipValue = "", fieldName, setEditorValue } = props;
  const counterRef = useRef();
  const { quill, quillRef, Quill } = useQuill({ modules: { resize: true } });

  if (Quill && !quill) {
    const Resize = require("quill-resize-image");
    Quill.register("modules/resize", Resize);
  }

  // Insert Image(selected by user) to quill
  const insertToEditor = useCallback(
    (url) => {
      const range = quill.getSelection();
      quill.insertEmbed(range.index, "image", url);
    },
    [quill]
  );

  // Open Dialog to select Image File
  const selectLocalImage = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      uploadEditorFile(file).then((res) => {
        insertToEditor(res.data?.fullPath);
      });
    };
  }, [uploadEditorFile, insertToEditor]);

  useEffect(() => {
    if (quill) {
      quill.getModule("toolbar").addHandler("image", selectLocalImage);
      quill.on("text-change", (delta, oldDelta, source) => {
        //console.log("Text change!");
        //console.log(quill.getText()); // Get text only
        //console.log(quill.getContents()); // Get delta contents
        //console.log(quill.root.innerHTML); // Get innerHTML using quill
        setEditorValue(fieldName, quill.root.innerHTML);
        //console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
      });
    }
  }, [quill, setEditorValue]);

  useEffect(() => {
    if (ipValue && quillRef) {
      quillRef.current.innerHTML = ipValue;
    }
  }, [ipValue, quillRef]);

  return (
    <div>
      <div ref={quillRef} />
      <div ref={counterRef} />
    </div>
  );
}

export default DraftEditor;

import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import axiosInstance from "../../../services/AxiosInstance";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { object, string } from "yup";
import {
  addSubRecruiter,
  updateRecruiterStatus,
  updateRecruiter,
} from "../../../services/SubRecruiterService";
import { downloadCSV } from "../../../helpers/dataTableHelper";

let userSchema = object().shape({
  name: string().required({ name: "Name is required." }),
  email: string()
    .email({ email: "Enter correct email." })
    .required({ email: "Email is required." }),
  password: string()
    .required({ password: "Password is required." })
    .min(6, { password: "Paswword must be 6 digit long." }),
});

let userUpdateSchema = object().shape({
  name: string().required({ name: "Name is required." }),
  email: string()
    .email({ email: "Enter correct email." })
    .required({ email: "Email is required." }),
});

export default function ManageSubRecruiter() {
  const [addModel, setAddModal] = useState(false);
  const [updateModel, setUpdateModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRows] = useState([]);
  const [errors, setError] = useState({});
  const [toggleCleared, setToggleCleared] = useState(false);
  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [editData, setEditData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [sorting, setSorting] = useState({
    sort_by: "created_at",
    sort_direction: "DESC",
  });

  const onEdit = useCallback(
    (row) => {
      setError({});
      const { id, name, email } = row;
      setEditData({
        id,
        name,
        email,
      });
      setUpdateModal(true);
    },
    [setEditData, setError]
  );

  const fetchUsers = useCallback(
    async (page, size = perPage) => {
      setLoading(true);
      let params = {
        page: page,
        limit: size,
        sort_by: "created_at",
        sort_direction: "DESC",
        keyword: "",
        ...sorting,
      };

      axiosInstance
        .post("sub-recruiter/list", params)
        .then((res) => {
          setData(res?.data?.data);
          setTotalRows(res?.data?.totalRecords);
          setLoading(false);
        })
        .catch((err) => {
          swal("Error", err?.message, "error");
          setLoading(false);
        });
    },
    [perPage, sorting]
  );

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage, fetchUsers]);

  const columns = useMemo(
    () => [
      {
        name: "Sr.no",
        selector: (row) => row.id,
        sortable: true,
        sortField: "id",
      },
      {
        name: "Name",
        selector: (row) => row.name,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "name",
      },
      {
        name: "Email",
        selector: (row) => row.email,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "email",
      },
      {
        name: "Posted Jobs",
        selector: (row) => row.posted_jobs,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "posted_jobs",
      },
      {
        name: "Unlocked Profiles",
        selector: (row) => row.unlock_profiles,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "unlock_profiles",
      },
      {
        name: "Favourite Profiles",
        selector: (row) => row.fav_profiles,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "fav_profiles",
      },
      {
        name: "Last Login Time",
        selector: (row) => row.last_login_time,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Time Spent",
        selector: (row) => row.time_spend,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Candidate status changed",
        selector: (row) => row.candidate_status_changed,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Offers",
        selector: (row) => "",
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Created Time",
        selector: (row) => row.created_at,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Registration Time",
        selector: (row) => row.created_at,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Activation",
        button: "true",
        cell: (row) => (
          <button
            className="btn btn-sm btn-outline-primary p-2"
            onClick={() =>
              changeStatus([row?.id], row?.published === "1" ? 0 : 1)
            }
          >
            <i
              className={`fa ${
                row?.published === "1" ? "fa-lock" : "fa-unlock"
              }`}
            ></i>{" "}
            {row?.published === "1" ? "Inactive" : "Activate"}
          </button>
        ),
      },
      {
        name: "Edit",
        button: "true",
        wrap: true,
        minWidth: "150px",
        cell: (row) => (
          <button
            className="btn btn-sm btn-outline-primary p-2"
            onClick={() => onEdit(row)}
          >
            <i className="fa fa-edit"></i> Edit Profile
          </button>
        ),
      },
      {
        name: "View Posted Jobs",
        button: "true",
        wrap: true,
        minWidth: "150px",
        cell: (row) => (
         <Link to={`/manage-jobs?rid=${row.id}`}>
          <button
            className="btn btn-sm btn-outline-primary p-2"
            //onClick={() => swal("", "Work in progress", "info")}
          >
            <i className="fa fa-eye"></i> View Jobs
          </button>
          </Link>
        ),
      },
      {
        name: "Reports",
        button: "true",
        wrap: true,
        minWidth: "150px",
        cell: (row) => (
          <Link to={`/${row.id}/recruiter-report`}>
            <button className="btn btn-sm btn-outline-primary p-2">
              <i className="fa fa-eye"></i> Reports
            </button>
          </Link>
        ),
      },
    ],
    [onEdit]
  );

  const handlePageChange = (page) => {
    fetchUsers(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchUsers(page, newPerPage);
    setPerPage(newPerPage);
  };

  const handleAddRecruiter = useCallback(
    (e) => {
      e.preventDefault();
      userSchema
        .validate(inputData, { strict: false, abortEarly: false })
        .then((resp) => {
          setError({});
          addSubRecruiter(resp)
            .then((response) => {
              if (response?.data?.code === "success") {
                setInputData({
                  name: "",
                  email: "",
                  password: "",
                });
                setAddModal(false);
                swal("Great Job!", response?.data.message, "success");
                fetchUsers(currentPage);
              } else {
                swal("Opps", response?.data.message, "error");
              }
            })
            .catch((e) => console.log(e));
        })
        .catch((err) => {
          let errObj = {};
          err.errors.map((msg, i) => {
            let keys = Object.keys(msg);
            if (errObj[keys[0]] === undefined) {
              errObj = { ...errObj, [keys[0]]: msg[keys[0]] };
            }
            return null;
          });
          setError(errObj);
        });
    },
    [inputData, currentPage, fetchUsers]
  );

  const handleUpdateRecruiter = useCallback(
    (e) => {
      e.preventDefault();
      userUpdateSchema
        .validate(editData, { strict: false, abortEarly: false })
        .then((resp) => {
          if (
            resp?.password !== undefined &&
            resp?.password !== "" &&
            editData?.password.length < 6
          ) {
            setError({ password: "Paswword must be 6 digit long." });
            return false;
          }
          setError({});

          updateRecruiter(editData?.id, resp)
            .then((response) => {
              if (response?.data?.code === "success") {
                setEditData({
                  name: "",
                  email: "",
                  password: "",
                });
                setUpdateModal(false);
                swal("Great Job!", response?.data.message, "success");
                fetchUsers(currentPage);
              } else {
                swal("Opps", response?.data.message, "error");
              }
            })
            .catch((e) => console.log(e));
        })
        .catch((err) => {
          let errObj = {};
          err?.errors?.map((msg, i) => {
            let keys = Object.keys(msg);
            if (errObj[keys[0]] === undefined) {
              errObj = { ...errObj, [keys[0]]: msg[keys[0]] };
            }
            return null;
          });
          setError(errObj);
        });
    },
    [editData, currentPage, fetchUsers]
  );

  const changeStatus = useCallback(
    (ids, status) => {
      if (ids?.length < 1) {
        swal(
          "Opps",
          `Please select at least 1 record to ${
            status === 0 ? "Inactive" : "Active"
          }.`,
          "error"
        );
        return false;
      }

      swal({
        title: "Are you sure?",
        text:
          "Status will be change to " +
          (status === 0 ? "Inactive" : "Active") +
          ".",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          updateRecruiterStatus(ids, status)
            .then((resp) => {
              if (resp?.data.code === "success") {
                swal("Great Job!", resp?.data.message, "success");
                setToggleCleared(!toggleCleared);
                fetchUsers(currentPage);
              } else if (resp?.data.code === "error") {
                swal("Opps", resp?.data.message, "error");
              }
            })
            .catch((e) => console.log(e));
        }
      });
    },
    [currentPage, fetchUsers, setToggleCleared, toggleCleared]
  );

  const handleChangeStatus = useCallback(
    (status) => {
      const ids = selectedRow.map((c, i) => {
        return c.id;
      });
      changeStatus(ids, status);
    },
    [selectedRow, changeStatus]
  );

  const handleSort = useCallback(
    (column, sortDirection) => {
      if (column?.sortField !== undefined) {
        setSorting({
          ...sorting,
          sort_by: column.sortField,
          sort_direction: sortDirection,
        });
      }
    },
    [setSorting, sorting]
  );

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600  me-auto"></h4>
        <div>
          <button
            onClick={() => setAddModal(true)}
            className="btn btn-info me-3 btn-sm"
          >
            <i className="fas fa-plus-circle me-1"> </i>New Recruiter
          </button>
          {/* <button
            className="btn btn-warning me-3 btn-sm"
            onClick={() => swal("", "Work in progress", "info")}
          >
            Generate Usage Report
          </button> */}

          <button
            onClick={() => handleChangeStatus(1)}
            className="btn btn-warning btn-sm me-3"
          >
            <i className="fas fa-thumbs-up me-1"></i>Active Selected
          </button>

          <button
            onClick={() => handleChangeStatus(0)}
            className="btn btn-danger btn-sm me-3"
          >
            <i className="fas fa-thumbs-down me-1"></i>Inactive Selected
          </button>

          <button
            onClick={() => downloadCSV(data,'sub-recruiter-')}
            className="btn btn-warning btn-sm"
          >
            <i className="fas fa-file-csv"></i>Export
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <DataTable
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              selectableRows
              persistTableHead
              clearSelectedRows={toggleCleared}
              onSelectedRowsChange={({ selectedRows }) =>
                setSelectedRows(selectedRows)
              }
              onSort={handleSort}
              sortServer
              fixedHeader={true}
              fixedHeaderScrollHeight={'500px'}
            />
            {/* <table className="table table-bordered table-striped display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr>
                  <th></th>
                  <th>Sr.no</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Posted Jobs</th>
                  <th>Unlocked Profiles</th>
                  <th>Favourite Profiles</th>
                  <th>Last Login Time</th>
                  <th>Time Spent</th>
                  <th>Candidate status changed</th>
                  <th>Offers</th>
                  <th>Created Time</th>
                  <th>Registration Time</th>
                  <th>Edit</th>
                  <th style={{width:'100px'}}>View Posted Jobs</th>
                  <th>Reports</th>
                </tr>
              </thead>
              <tbody>
                {contacts.map((contact, index) => (
                  <tr key={index}>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>{index++}</td>
                    <td>{contact.name}</td>
                    <td>{contact.email}</td>
                    <td>{contact.job_posted_by_sub_recruiter}</td>
                    <td>{contact.unlocked_profiles_count}</td>
                    <td>{contact.favourite_profiles_count}</td>
                    <td>{contact.last_login_time}</td>
                    <td>{contact.spent_time}</td>
                    <td>{contact.candidate_status_changed_by_rcuiter_count}</td>
                    <td>{contact.offers_input}</td>
                    <td>{contact.registration_time}</td>
                    <td>{contact.registration_time}</td>
                    <td>
                      <a className="btn btn-sm btn-outline-primary">
                        <i className="fas fa-edit fs-4"></i>
                        Edit Profile
                      </a>
                    </td>
                    <td>
                      <a className="btn btn-sm btn-outline-primary">
                        <i className="fas fa-eye fs-4"></i>
                        <br/>View Jobs
                      </a>
                    </td>
                    <td>
                      <a className="btn btn-sm btn-outline-primary">
                        <i className="fas fa-eye fs-4"></i>
                        Reports
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> */}
          </div>
        </div>
        {/* <div className="d-md-flex d-block align-items-center justify-content-between text-center  flex-wrap mt-md-0 mt-3">
          <div className="mb-md-0 mb-2">
            <h5 className="mb-0">Showing 1 to 7 of 7 entries</h5>
          </div>
          <nav>
            <ul className="pagination pagination-circle justify-content-center">
              <li className="page-item page-indicator">
                <Link to={"#"} className="page-link">
                  <i className="fa fa-angle-double-left" />
                </Link>
              </li>
              <li className="page-item active">
                <Link to={"#"} className="page-link">
                  1
                </Link>
              </li>
              <li className="page-item page-indicator">
                <Link to={"#"} className="page-link">
                  <i className="fa fa-angle-double-right" />
                </Link>
              </li>
            </ul>
          </nav>
        </div> */}
      </div>
      <Modal className="modal fade" show={addModel} onHide={setAddModal}>
        <div role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Create New Recruiter</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setAddModal(false)}
                data-dismiss="modal"
              >
                <span></span>
              </button>
            </div>
            <form onSubmit={handleAddRecruiter}>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <div className="mb-3 form-group">
                          <label htmlFor="name">
                            Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="name"
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                name: e.target.value,
                              })
                            }
                            className="form-control"
                            type="text"
                            name="name"
                            required=""
                          />
                          {errors?.name !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.name}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="email">
                            Email<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="email"
                            className="form-control"
                            type="text"
                            name="email"
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                email: e.target.value,
                              })
                            }
                            //required
                          />
                          {errors?.email !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.email}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="password">
                            Password<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="password"
                            className="form-control"
                            type="password"
                            name="password"
                            required=""
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                password: e.target.value,
                              })
                            }
                          />
                          {errors?.password !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.password}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-success"
                  //onClick={handleAddRecruiter}
                >
                  <i className="fa fa-save"></i> Save
                </button>
                <button
                  type="button"
                  onClick={() => setAddModal(false)}
                  className="btn btn-danger"
                >
                  <i className="flaticon-delete-1"></i> Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Modal className="modal fade" show={updateModel} onHide={setUpdateModal}>
        <div role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Update Recruiter</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setUpdateModal(false)}
                data-dismiss="modal"
              >
                <span></span>
              </button>
            </div>
            <form onSubmit={handleUpdateRecruiter}>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <div className="mb-3 form-group">
                          <label htmlFor="name">
                            Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="name"
                            onChange={(e) =>
                              setEditData({
                                ...editData,
                                name: e.target.value,
                              })
                            }
                            value={editData?.name}
                            className="form-control"
                            type="text"
                            name="name"
                          />
                          {errors?.name !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.name}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="email">
                            Email<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="email"
                            className="form-control"
                            type="text"
                            name="email"
                            onChange={(e) =>
                              setEditData({
                                ...editData,
                                email: e.target.value,
                              })
                            }
                            value={editData?.email}
                            //required
                          />
                          {errors?.email !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.email}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="password">
                            Password<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="password"
                            className="form-control"
                            type="password"
                            name="password"
                            onChange={(e) =>
                              setEditData({
                                ...editData,
                                password: e.target.value,
                              })
                            }
                          />
                          {errors?.password !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.password}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-success"
                  //onClick={handleUpdateRecruiter}
                >
                  <i className="fa fa-save"></i> Update
                </button>
                <button
                  type="button"
                  onClick={() => setUpdateModal(false)}
                  className="btn btn-danger"
                >
                  <i className="flaticon-delete-1"></i> Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

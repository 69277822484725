import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import { billingHistoryList } from "../../../services/BillingHistory";

export default function BillingHistory() {
  const tableClm = useMemo(() => {
    return [
      {
        name: "Plan Name",
        selector: (row) => row.plan_name + " (" + row.plan_id + ")",
        wrap: true,
        //'minWidth': "200px",
        sortable: true,
        sortField: "plan_name",
      },
      {
        name: "Plan Price",
        selector: (row) => row.plan_price,
        wrap: true,
        //'minWidth': "200px",
        sortable: true,
        sortField: "plan_price",
      },
      {
        name: "Plan Days",
        selector: (row) => row.plan_days,
        wrap: true,
        //'minWidth': "200px",
        sortable: true,
        sortField: "plan_days",
      },
      {
        name: "Plan Start",
        selector: (row) => row.plan_start_date,
        wrap: true,
        //'minWidth': "200px",
        sortable: true,
        sortField: "plan_start_date",
      },
      {
        name: "Plan Expire",
        selector: (row) => row.plan_expire_date,
        wrap: true,
        //'minWidth': "200px",
        sortable: true,
        sortField: "plan_expire_date",
      },
      {
        name: "Total Unlock Quota Plan/Used",
        selector: (row) =>
          row.plan_total_unlock_quota + "/" + row.total_unlocked_remaining,
        wrap: true,
        //'minWidth': "200px",
        //sortable: true,
        //sortField: "preferred_name",
      },
      {
        name: "Unlock General Quota Plan/Used",
        selector: (row) =>
          row.plan_general_unlock_quota + "/" + row.general_unlocked_remaining,
        wrap: true,
        //'minWidth': "200px",
        //sortable: true,
        //sortField: "preferred_name",
      },
      {
        name: "Unlock Application Quota Plan/Used",
        selector: (row) =>
          row.plan_applicants_unlock_quota +
          "/" +
          row.applicants_unlocked_remaining,
        wrap: true,
        //'minWidth': "200px",
        //sortable: true,
        //sortField: "preferred_name",
      },
      {
        name: "Total Jobs Plan/Used",
        selector: (row) =>
          row.plan_jobs_limit + "/" + row.posted_jobs_remaining,
        wrap: true,
        //'minWidth': "200px",
        //sortable: true,
        //sortField: "preferred_name",
      },
      {
        name: "Total Sub-recruiter Plan/Used",
        selector: (row) =>
          row.plan_sub_recruiter_quota + "/" + row.subrecruiter_remaining_quota,
        wrap: true,
        //'minWidth': "200px",
        //sortable: true,
        //sortField: "preferred_name",
      },

      // {
      //   name: "Email",
      //   selector: (row) => row.email,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "email",
      // },
      // {
      //   name: "Phone",
      //   selector: (row) => row.ph_country_code + " " + row.phone_number,
      //   wrap: true,
      //   //'minWidth': "200px",
      // },
      // {
      //   name: "Out at Work",
      //   selector: (row) => row.out_at_work,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "out_at_work",
      // },
      // {
      //   name: "Identify As",
      //   selector: (row) => row.identify_name,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "identify_name",
      // },
      // {
      //   name: "Current City",
      //   selector: (row) => row.city_name,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "city_name",
      // },
      // {
      //   name: "About Yourself",
      //   selector: (row) => row.about_yourself,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "about_yourself",
      // },
      // {
      //   name: "Qualification",
      //   selector: (row) => row.highest_education,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "highest_education",
      // },
      // {
      //   name: "Year of Passing",
      //   selector: (row) => row.highest_education_passing_year,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "highest_education_passing_year",
      // },
      // {
      //   name: "College",
      //   selector: (row) => row.institution_name,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "institution_name",
      // },
      // {
      //   name: "Apply for internship",
      //   selector: (row) => row.interested_in_internships,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "interested_in_internships",
      // },
      // {
      //   name: "Over 16 years of age",
      //   selector: (row) => row.over_eighteen_years,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "over_eighteen_years",
      // },
      // {
      //   name: "Internship month(s)",
      //   selector: (row) => row.internship_available_months,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "internship_available_months",
      // },
      // {
      //   name: "Aggregate percentage",
      //   selector: (row) => row.aggregate_percentage,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "aggregate_percentage",
      // },
      // {
      //   name: "Experience",
      //   selector: (row) => row.total_experience,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "total_experience",
      // },
      // {
      //   name: "Current Employer",
      //   selector: (row) => row.current_previous_company,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "current_previous_company",
      // },
      // {
      //   name: "Current Role",
      //   selector: (row) => row.current_profile_role,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "current_profile_role",
      // },
      // {
      //   name: "Skills",
      //   selector: (row) => row.skills,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "skills",
      // },
      // {
      //   name: "CTC",
      //   selector: (row) => row.current_salary,
      //   wrap: true,
      //   //'minWidth': "200px",
      //   sortable: true,
      //   sortField: "current_salary",
      // },
    ];
  }, []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [toggleCleared, setToggleCleared] = useState(false);

  const [columns] = useState(tableClm);

  //const [filterText, setFilterText] = useState("");

  const [reqParams, setReqParams] = useState({
    page: currentPage,
    limit: perPage,
     sort_by: "created_at",
     sort_direction: "DESC",
    // keyword: ""
  });

  const [fetch, setFetch] = useState(true);

  const fetchUsers = useCallback((params) => {
    setToggleCleared(!toggleCleared);
    setLoading(true);
    billingHistoryList(params)
      .then((res) => {
        setData(res?.data?.data);
        setTotalRows(res?.data?.totalRecords);
        setLoading(false);
      })
      .catch((err) => {
        swal("Error", err?.message, "error");
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (fetch) {
      fetchUsers(reqParams);
    }
  }, [fetch, reqParams]);

  //  const handleSearchText = useCallback(
  //    (text) => {
  //      setReqParams((state) => {
  //        return { ...state, keyword: text };
  //      });
  //      setFetch(true);
  //    },
  //    [setReqParams, setFetch]
  //  );

  const handlePageChange = useCallback(
    (page) => {
      setCurrentPage(page);
      setReqParams({
        ...reqParams,
        page: page,
      });
      setFetch(true);
    },
    [setReqParams, reqParams, setFetch]
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage, page) => {
      setPerPage(newPerPage);
      setReqParams({
        ...reqParams,
        limit: newPerPage,
      });
      setFetch(true);
    },
    [setReqParams, reqParams, setFetch]
  );

   const handleSort = useCallback(
     (column, sortDirection) => {
       if (column?.sortField !== undefined) {
         setReqParams({
           ...reqParams,
           sort_by: column.sortField,
           sort_direction: sortDirection,
         });
         setFetch(true);
       }
     },
     [reqParams, setReqParams, setFetch]
   );

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="table-responsive">
          <DataTable
            className="table display dataTablesCard job-table table-responsive-xl card-table dataTable no-footer"
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 200, 500]}
            onChangePage={handlePageChange}
            //selectableRows
            persistTableHead
            // subHeader
            // subHeaderComponent={
            //   <SearchInputComponent setFilterText={handleSearchText} />
            // }
            onSelectedRowsChange={({ selectedRows }) => {
              //console.log(selectedRows)
              //setSelectedRows(selectedRows);
            }}
            clearSelectedRows={toggleCleared}
            onSort={handleSort}
            sortServer
            fixedHeader={true}
              fixedHeaderScrollHeight={'500px'}
          />
        </div>
      </div>
    </div>
  );
}
